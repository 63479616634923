import React from 'react';
import Tile from './Tile';
import Feed from './Feed';
import { tiles } from '../global/config';

const App = () => (
    <div className="container-fluid">
        <div className="d-flex flex-column">
            <div className="row no-gutters order-2 order-md-1">
                {tiles.map((tile) => (
                    <Tile name={tile.name} url={tile.url} color={tile.color} background={tile.background} key={tile.url} />
                ))}
            </div>
            <div className="order-1 order-md-2">
                <Feed />
            </div>
        </div>
    </div>
);

export default App;

import React from 'react';
import PropTypes from 'prop-types';

const Tile = (props) => {
    const { name, url, background, color } = props;
    const style = { borderColor: background, color };

    return (
        <div className="col-12 col-md-4 col-lg-3 p-md-1 mb-1 mb-md-0">
            <a href={url} className="btn btn-secondary-lighter p-3 d-flex text-decoration-none tile font-size-bigger rounded" style={style} rel="noopener noreferrer nofollow">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="mr-2 flex-shrink-0 d-block" viewBox="0 0 512 512" fill={background}>
                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" />
                </svg>
                <div className="tile-content flex-grow-1 overflow-hidden">
                    <div className="tile-name mb-lg-2">{name}</div>
                    <div className="font-size-small text-tertiary text-truncate text-nowrap overflow-hidden d-none d-lg-block">{url}</div>
                </div>
            </a>
        </div>
    );
};

Tile.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
};

export default Tile;

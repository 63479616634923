import PropTypes from 'prop-types';
import React from 'react';

const Tab = ({ label, onClick, selected, unreadCount }) => (
    <button onClick={onClick} aria-selected={selected} type="button" role="tab" className="btn btn-secondary text-nowrap transition p-3 d-flex align-items-center justify-content-between w-100 text-white text-decoration-none mr-2 mr-lg-0 mb-2 font-size-bigger rounded">
        <div className="text-truncate">{label}</div>
        {unreadCount > 0 ? <div className="badge bg-blue text-white ml-2 flex-shrink-0">{unreadCount}</div> : ''}
    </button>
);

Tab.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    unreadCount: PropTypes.number.isRequired,
};

export default Tab;

import PropTypes from 'prop-types';
import React from 'react';
import FeedDate from '../Date';

const Article = ({ guid, pubDate, title, handleClickOnLink, isClicked, contentEncoded, isFirst }) => (
    <article className={`${isFirst ? 'border-top border-secondary-lighter' : ''} position-relative pt-3 pb-3 pl-2 pr-2 border-bottom border-secondary-lighter`} key={guid}>
        <h2 className={`epsilon mb-0 ${isClicked ? 'font-weight-normal' : ''}`}>
            <a className={`${isClicked ? 'text-tertiary' : 'text-white'} article-title stretched-link`} target="_blank" rel="noopener noreferrer" href={guid} onClick={() => handleClickOnLink(guid)}>
                {title}
            </a>
        </h2>
        <div className="row no-gutters d-flex align-items-center g-2 mt-2">
            <FeedDate date={pubDate} />
        </div>
    </article>
);

Article.propTypes = {
    guid: PropTypes.string.isRequired,
    handleClickOnLink: PropTypes.func.isRequired,
    isClicked: PropTypes.bool.isRequired,
    pubDate: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    contentEncoded: PropTypes.string.isRequired,
    isFirst: PropTypes.bool.isRequired,
};

export default Article;
